
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { isoDateToDay, isoDateToMonth, isoDateToHour } from '@/helpers/date'
import {
  getProductOfSeller2,
  getEventsWithProducts,
  removeProduct,
  addProduct,
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import axios from 'axios'
import Events from '@/components/products/events.vue'

@Component({
  components: {
    SellerHeader,
    Events,
  },
})
export default class ListProductSellers extends Vue {
  sellersEventProduct: [] = []
  sellersProductFiltered: [] = []
  sellersProductTotalPages = 1
  sellersProductPage = 1
  filter = ''
  sellerId: number
  loading: false
  eventsWithProducts: any = []
  eventsWithProductsFilter = []
  searchQuery: string = ''
  viewSellerInProducts = 0
  isCollapsed = false

  page = 1
  totalPages = 1

  public total_products_sold_type = null

  ocupate(totalTicket: number | null) {
    return totalTicket || 0
  }

  dateToHours(isoDate: string) {
    return isoDateToHour(isoDate)
  }

  async getProductOfSeller(sellerId: number) {
    try {
      this.loading = true
      const { data } = await getProductOfSeller2(sellerId)
      this.sellersEventProduct = data
      console.log(data)
      this.loading = false
    } catch (error) {
      console.error(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async updatePagination(page) {
    const events_products = await getEventsWithProducts(this.sellerId, page)

    this.eventsWithProducts = events_products.data
    this.eventsWithProductsFilter = events_products.data

    this.$router.push({ query: { page: page } })
  }

  async getEventsWithProducts(sellerId: number) {
    let page =
      this.$route.query.page != undefined ? this.$route.query.page : false
    const response = await getEventsWithProducts(sellerId, page)
    return response
  }

  newData(data) {
    this.sellersEventProduct = data
  }

  async search(events) {
    this.eventsWithProductsFilter = this.eventsWithProducts.filter()
  }

  async mounted() {
    if (this.$route.params.sellerId) {
      this.sellerId = parseInt(this.$route.params.sellerId)
      console.log(this.sellerId)
      await this.getProductOfSeller(this.sellerId)

      const events_products = await this.getEventsWithProducts(this.sellerId)

      this.eventsWithProducts = events_products.data
      this.eventsWithProductsFilter = events_products.data
      this.page = events_products.page
      this.totalPages = events_products.total
    }
  }
}
